





































































import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {AgGridVue} from 'ag-grid-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import FilterList from "@/components/filter/FilterList.vue";
import {QueryLoadOption, SortingInfo} from "@/models/QueryLoadOptions";
import SelectPageSize from "@/components/select-page-size/select-page-size.vue";
import {OrderService} from '@/views/order/OrderService';
import {AttributeType} from "@/constants/Attribute";
import {DiscountType, Status} from '@/constants/Order';
import {LoadResult} from "@/models/LoadResult";
import {FilterStateResult} from "@/models/filter/filter-state-result";
import SelectCellRenderer from "@/views/order/order-return/cell-renderer/SelectCellRenderer.vue";
import _filter from "lodash/filter";
import _map from "lodash/map";
import _each from "lodash/each";
import {formatNumber} from "@/common";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import moment from 'moment';

@Component({
    components: {
        AgGridVue,
        VuePerfectScrollbar,
        FilterList,
        SelectPageSize,
        SelectCellRenderer
    }
})
export default class OrderReturnList extends Vue {
    @Ref('vsPopup') vsPopup!: any;
    // @Prop({default: true, type: Function}) addTab!: any;

    private orderService: OrderService;

    constructor(){
        super();

        this.orderService = new OrderService();
        this.loadOptions.sort = [new SortingInfo('createTime')];
    }

    searchQuery = '';
    isPopupActive: boolean = false;
    gridOptions: any = null;
    gridApi: any = null;
    columnApi: any = null;
    columnDefs: any = null;
    gridColumnApi: any = null;
    maxPageNumbers = 7;
    totalRows = 0;
    totalPages: number = 0;
    currentPage = 1;
    rowData: any = [];
    attributeOptions: FilterAttributeResult[] = [];
    loadOptions: QueryLoadOption = new QueryLoadOption();
    attributeSearchByKeywords = ['normalizedName'];

    settings: any = {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70,
    };

    defaultColDef = {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        filter: true,
    };

    beforeMount(){
        this.gridOptions = {
            localeText: {
                noRowsToShow: 'Không có dữ liệu',
            }
        };

        this.getColumnDefaultApi();
    }

    mounted(){
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
    }

    get paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 10;
    }

    openPopup(){
        this.isPopupActive = !this.isPopupActive;
        this.search();
    }

    beginSearch(page: number) {
        if (this.searchQuery == undefined || this.searchQuery.length < 2) {
            return;
        }

        this.search();
    }

    @Watch('currentPage')
    onCurrentPageChange(value: number, oldValue: number) {
        this.search();
    }

    close(){
        this.isPopupActive = !this.isPopupActive;
    }

    onSelect(id: string){
        this.close();
    }

    searchByFilter(filterQuery: string) {
        this.loadOptions.filterQuery = filterQuery;
        this.search();
    }

    showModalFilter(value: FilterAttributeResult[]) {
        if (!value || value.length === 0) {
            this.renderAttributeOptions();
        }

        setTimeout(()=>{
            this.vsPopup.$el.style.zIndex = 52003
        }, 10)
    }

    changePageSize(pageSize: number) {
        this.gridApi.paginationSetPageSize(pageSize);
        this.currentPage = 1;
        this.setFilterState(this.currentPage, pageSize, this.loadOptions);

        this.search();
    }

    onGridReady(params: any) {
        setTimeout(() => {
            this.gridApi.sizeColumnsToFit();
        }, 500);
    }

    onReturnFast(){
        // this.$router.push({ name: 'orderReturn'});
        this.$emit('returnFastSelected');
        this.close();
    }

    private setFilterState(currentPage: number, pageSize: number, loadOption: QueryLoadOption) {
        let filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (!filterState) {
            filterState = new FilterStateResult();
        }

        filterState.pageSize = pageSize;
        filterState.currentPage = currentPage;
        filterState.loadOption = loadOption;
        filterState.module = this.$route.fullPath;
        filterState.attributeOptions = this.attributeOptions;

        // Cập nhật lại filter state
        this.$store.dispatch('filter/setFilterState', filterState);
    }

    private renderAttributeOptions() {
        this.orderService.getAttributeResult().then((result: FilterAttributeResult[]) => {
            if (result && result.length > 0) {
                _each(result, (item: FilterAttributeResult) => {
                    switch (item.attributeId) {
                        case 'DiscountType':
                            item.attributeOptions = [{
                                id: DiscountType.Percent,
                                name: 'Phần trăm',
                                data: null
                            }, {
                                id: DiscountType.Money,
                                name: 'Tiền',
                                data: null
                            }];
                            item.type = AttributeType.CheckList;
                            break;

                        case 'Status':
                            item.attributeOptions = [{
                                id: Status.Draft,
                                name: 'Nháp',
                                data: null
                            }, {
                                id: Status.GoodsIssued,
                                name: 'Lưu và xuất',
                                data: null
                            }, {
                                id: Status.Ordered,
                                name: 'Lưu và gửi',
                                data: null
                            }, {
                                id: Status.TotalOrder,
                                name: 'Đơn tổng',
                                data: null
                            }];
                            item.type = AttributeType.CheckList;
                            break;
                        // case 'SalerId':
                        case 'CreatorId':
                            item.urlSuggestion = `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`;
                            item.type = AttributeType.DropdownList;
                            break;
                        case 'CustomerId':
                            item.urlSuggestion = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers/suggestions`;
                            item.type = AttributeType.DropdownList;
                            break;
                        default:
                            break;
                    }
                })

                this.attributeOptions = result;
            }
        });
    }

    private async search() {
        this.openLoading(true);
        const filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);

        if (filterState) {
            this.loadOptions.filterQuery = filterState.loadOption.filterQuery;
            this.gridApi.paginationSetPageSize(filterState.pageSize);
        }

        this.loadOptions.skip = (this.currentPage - 1) * this.paginationPageSize;
        this.loadOptions.take = this.paginationPageSize;
        this.loadOptions.remoteSelect = false;

        this.orderService.searchOrderReturn(this.loadOptions)
            .finally(() => {
                this.openLoading(false);
            })
            .then((result) => {
                let resultData = result.data;
                let datetimeFormat = 'DD-MM-YYYY HH:mm';

                if (resultData) {
                    _each(resultData, (item) => {
                        item.createTime = moment(item.createTime).format(datetimeFormat);
                    })
                }

                this.rowData = result.data;
                this.totalRows = result.totalCount;
                this.setTotalPage(result.totalCount);
            })
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }

    private setTotalPage(totalRows: number) {
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.paginationPageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.paginationPageSize));
        }
    }

    private getColumnDefaultApi() {
        this.columnDefs = [
            {
                headerName: 'Mã đơn hàng',
                field: 'code',
                width: 150,
            },
            {
                headerName: "Ngày tạo",
                field: 'createTime',
                width: 210,
            },
            {
                headerName: 'Người tạo',
                field: 'creatorFullName',
                width: 200,
            },
            {
                headerName: 'Khách hàng',
                field: 'customerName',
                width: 200,
            },
            {
                headerName: 'Tổng cộng',
                field: 'totalPrice',
                width: 150,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
            },
            {
                headerName: '',
                field: 'select',
                filter: false,
                width: 100,
                cellRendererFramework: SelectCellRenderer,
                cellRendererParams: {
                    onClose: this.onSelect
                },
                cellStyle: {textAlign: "center"}
            }
        ];
    }
}
