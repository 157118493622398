import { OrderDetail } from "@/models/order/OrderDetail";
import { Receiver } from "@/models/order/Receiver";
import { Payment } from "@/models/order/Payment";
import { OrderCustomer } from "@/models/order/OrderCustomer";
import { DiscountType, OrderType } from "@/constants/Order";
import moment from "moment";
import { FileResult } from "@/components/upload-file/FileResult";

export class Order {
    id: string;
    code: string;
    orderDate: any;
    deliveryDate: DeliveryDate;
    size: Size;
    installationMethod?: number;
    paymentMethod: number;
    shippingMethod?: number;
    routeId: string;
    routeName: string;
    collectionMoney?: number;
    discount: number;
    discountType: DiscountType;
    vat: number;
    shippingPrice?: number;

    // Tiền khách phả trả.
    totalPrice: number;
    totalProduct?: number;
    storeId: string;
    weight?: number;
    note: string;
    noteInternal: string;
    condition: any;
    status: number;
    receiver: Receiver;
    orderDetails: OrderDetail[];
    orderPayments: Array<Payment>;

    // Tổng tiền chưa bao gồm phụ phí.
    amount: number;

    // Khách đã thanh toán.
    paidPrice: number;

    // Tiền thừa
    excessMoney: number;

    isSplitLine: boolean;
    orderCustomer: OrderCustomer;
    installations: Array<any>;
    shippings: Array<any>;

    deliveryPlans?: any;
    fromOrderId: string;
    storeCode: string;
    storeName: string;
    type: OrderType;

    /**
     * Phí trả hàng.
     */
    returnCharge: number;

    /**
     * Tổng tiền trả
     */
    totalAmountReturn: number;

    /**
     * Nhập kho khi trả hàng.
     */
    isImportInventory: boolean;

    /**
     * Danh sách sản phẩm trả.
     */
    orderDetailsReturn: Array<OrderDetail>;

    /**
     * Tổng tiền trả hàng
     */
    totalPriceReturn: number;

    // Mua hàng.
    /**
     * Tổng tiền đổi hàng.
     */
    isExportInventory: boolean;

    paidPriceExchangeReturn?: number;

    /**
     * Tiền hàng trả nếu chọn trả nhanh
     */
    amountReturn?: number;

    vatReturn: number;
    isReturnAll: boolean;
    branchId: string;
    branchName: string;
    excessMoneyReturn: number;
    paymentTerm?: number;
    fileIds: Array<string>;
    attachFiles: FileResult[];
    paymentTermDate: any;

    constructor() {
        this.id = "";
        this.orderDate = moment().format();
        this.paymentTermDate = null;
        // this.orderDate = null;
        this.deliveryDate = new DeliveryDate();
        this.installationMethod = undefined;
        this.shippingMethod = undefined;
        this.routeId = "";
        this.storeId = "";
        this.note = "";
        this.noteInternal = "";
        this.status = 0;
        this.orderDetails = new Array<OrderDetail>();
        this.receiver = new Receiver("","");
        this.size = new Size();
        this.amount = 0;
        this.excessMoney = 0;
        this.totalPrice = 0;
        this.discountType = DiscountType.Percent;
        this.discount = 0;
        this.vat = 0;
        this.isSplitLine = false;
        this.orderCustomer = new OrderCustomer();
        this.shippingPrice = undefined;
        this.paidPrice = 0;
        this.installations = new Array<any>();
        this.shippings = new Array<any>();
        this.storeCode = "";
        this.storeName = "";
        this.isExportInventory = true;
        this.isImportInventory = true;
        this.orderDetailsReturn = new Array<OrderDetail>();
        this.totalPriceReturn = 0;
        this.paidPriceExchangeReturn = undefined;
        this.amountReturn = undefined;
        this.vatReturn = 0;
        this.isReturnAll = false;
        this.branchId = '';
        this.branchName = '';
        this.excessMoneyReturn = 0;
        this.paymentTerm = undefined;
        this.fileIds = new Array<string>();
        this.attachFiles = new Array<FileResult>();
    }
}

export class DeliveryDate {
    from: any;
    to: any;

    constructor(){
        // this.from = moment(new Date()).format('DD-MM-YYYY HH:mm');
        // this.to = moment(new Date()).format('DD-MM-YYYY HH:mm');
        this.to = moment().format();
        this.from = moment().format();
    }
}

export class Size {
    long: number;
    wide: number;
    high: number;
}

export interface InstallationShippingStaff {
    id: string;
    name: string;
    avatar: string;
}
