




























































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Printd from 'printd'
import { ReturnService } from "@/views/order/ReturnService";
import { ActionResult } from "@/models/ActionResult";
import { CustomerType } from "@/constants/Customer";
import { Money, VMoney } from 'v-money'
import moment from "moment";
import {ReturnPrintResult} from "@/views/order/return-print/ReturnPrintResult";
import { DiscountType } from "@/constants/Order";

@Component({
    components: {
        Money
    }
})
export default class ReturnPrint extends Vue {
    discountType = DiscountType;
    // customerType = CustomerType;
    isLoading = false;
    listOrder: any = [];
    moneyOptions = {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };
    quantityOptions = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    }
    css = `
        @page { size: auto;  margin: 5mm; }

        .print-page {
            font-size: 13px;
        }

        .w100pc {
            width: 100%;
        }

        .text-right {
            text-align: right;
        }

        .note {
            font-style: italic;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }

        table th, table, td {
            border: 1px solid #ddd;
            padding: 5px;
        }

        table.no-border, table.no-border tr td, table.no-border tr th {
            border: none !important;
        }

        .company-name {
            text-transform: uppercase;
            font-size: 16px;
        }

        h5 {
            margin-top: 10px;
            margin-bottom: 5px;
        }

        .text-center {
            text-align: center;
        }

        .bold {
            font-weight: bold;
        }

        .text-center {
            class="text-center"
         }

        input.money {
            font-family: "Montserrat", Helvetica, Arial, sans-serif;
            border: none;
            text-align: right;
            background: transparent;
            font-size: 14px;
        }

        .border-botton-only{
            border-right: 0;
            border-left: 0; 
            border-top: 0;
        }

        @media print {
          footer {page-break-after: always;}
        }
    `;

    print(orderIds: string[]) {
        if (!orderIds || orderIds.length === 0) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Vui lòng chọn đơn hàng cần in",
                color: "warning"
            });
            return;
        }
        const returnService = new ReturnService();
        this.isLoading = true;
        this.listOrder = [];
        returnService.getPrintDetail(orderIds)
            .then((result: ActionResult<ReturnPrintResult>[]) => {
                this.isLoading = false;
                if (result != null && result.length > 0) {
                    result.forEach((item: ActionResult<ReturnPrintResult>) => {
                        if (item.code > 0) {
                            const data = item.data;

                            if (data) {
                                data.orderDate = data.orderDate ? moment(data.orderDate).format('DD/MM/YYYY hh:mm') : '';
                                data.createTime = data.createTime ? moment(data.createTime).format('DD/MM/YYYY hh:mm') : '';

                                if (data.orderExchange) {
                                    data.payAmount = data.orderExchange.totalAmount - data.totalAmount;
                                }

                                this.listOrder.push(data);
                            }
                        }
                    });
                    setTimeout(() => {
                        const printer = new Printd();
                        const orderDetailPrintElement: any = this.$refs.orderPrintPage;
                        printer.print(orderDetailPrintElement, [
                            this.css
                        ])
                    }, 500);
                }
            })
            .catch(() => this.isLoading = false);
    }
}
