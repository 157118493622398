




































import {Component, Vue} from 'vue-property-decorator';
import {OrderService} from '@/views/order/OrderService';
import {Status} from "@/constants/Order";
import OrderStatus from "@/views/customer/insight/OrderStatus.vue";
import {TabState} from "@/store/order/order-state";
import { FormType } from "@/constants/Order";

@Component({})
export default class CellRendererActions extends Vue {
    private orderService: OrderService;
    params: any = null;

    constructor() {
        super();
        this.orderService = new OrderService();
    }

    confirmDeleteRecord() {
        let orderCode = this.params.data.code;

        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('ConfirmDelete'),
            text: this.$t('ConfirmOrderDeleteText', {data: orderCode}),
            accept: this.deleteRecord,
            acceptText: this.$t("Delete")
        })
    }

    async deleteRecord() {
        try {
            const result = await this.orderService.delete(this.params.data.id);

            if (result.code > 0) {
                this.$vs.notify({
                    color: 'success',
                    title: '',
                    text: 'Xóa đơn hàng thành công'
                })

                let gridApi = this.params.node.gridApi;

                if (gridApi) {
                    let rows = gridApi.getSelectedRows();

                    if (rows && rows.length > 0) {
                        gridApi.updateRowData({remove: rows});
                    }

                    gridApi.refreshCells();
                }
            }
        } catch (error) {
            this.$vs.notify({
                title: '',
                text: error.message,
                color: "danger"
            });

            return;
        }
    }

    orderReturn(){
        let orderId = this.params.data.id;

        const tab: TabState = {
            id: orderId,
            type: FormType.Return,
        };
        
        this.$store.dispatch('order/addTab', tab);
        this.$router.push({ name: 'orderAdd'});
    }

    get isEdit() {
        let status = this.params.data.status;

        return this.orderService.isEdit(status);
    }

    get isDelete() {
        let status = this.params.data.status;
        return this.orderService.isDelete(status);
    }

    get orderId() {
        return this.params.data.id;
    }

    get isReturn(){
        let status = this.params.data.status;
        
        return this.orderService.isReturn(status);
    }
}
