






import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class LinkCellRenderer extends Vue {
    params: any = null;

    get url() {
        let id = this.params.id ? this.params.id : this.params.data.id;

        return {name: this.params.routeName, params: {id: id}};
    }
}
