import { OrderDetail } from "@/models/order/OrderDetail";
import { DeliveryDate, Size } from "@/models/order/Order";
import { DiscountType, OrderType } from "@/constants/Order";
import { Receiver } from "@/models/order/Receiver";
import { Payment } from "@/models/order/Payment";
import { OrderCustomer } from "@/models/order/OrderCustomer";
import { FileResult } from "@/components/upload-file/FileResult";

export class OrderGetByIdResult {
    id: string;
    code: string;
    orderDate: any;
    deliveryDate: DeliveryDate;
    size: Size;
    installationMethod: number;
    shippingMethod: number;
    collectionMoney?: number;
    discount: number;
    discountType: DiscountType;
    vat: number;
    shippingPrice?: number;
    totalPrice: number;
    totalMoneyBeforeTax: number;
    paidPrice: number;
    totalProduct?: number;
    totalActualExport: number;
    weight?: number;
    storeId: string;
    storeCode: string;
    storeName: string;
    note: string;
    noteInternal: string;
    createTime: string;
    creatorId: string;
    creatorFullName: string;
    creatorPhoneNumber: string;
    status: number;
    receiver: Receiver;
    customer: OrderCustomer;
    payments: Array<Payment>;
    orderDetails: OrderDetail[];
    installations: Array<OrderInstallationResult>;
    shippings: Array<OrderInstallationResult>;
    fromOrderId: string;
    type: OrderType;
    branchId: string;
    branchName: string;
    cancelReason: string;
    remainPrice: number;
    returns: Array<OrderReturnResult>;
    totalAmountReturn: number;
    returnId: string;
    returnCode: string;
    // payAmount: number;
    paymentTerm: number;
    paymentTermDate: any;
    attachFiles: FileResult[];
    noteBusiness: string;

    constructor(){
        this.customer = new OrderCustomer();
        this.receiver = new Receiver("", "");
        this.orderDetails = new Array<OrderDetail>();
        this.deliveryDate = new DeliveryDate();
        this.attachFiles = new Array<FileResult>();
    }
}

export interface OrderInstallationResult {
    id: string;
    userId: string;
    name: string;
    text: string;
    avatar: string;
}


export class OrderReturnResult {
    id: string;
    code: string;
    createTime: string;
    creatorId: string;
    creatorFullName: string;
    status: number;
    totalAmountReturn: number;
}
