






import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class CellRendererStatus extends Vue {
    get chipColor() {
        return (value: any) => {
            return value === 0 ? 'danger' : 'success';
        }
    }

    get statusName() {
        return (value: any) => {
            return value === 0 ? 'Đã hủy' : 'Đã trả';
        }
    }
}
