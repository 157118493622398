
















































import {Component, Prop, Vue} from 'vue-property-decorator';
import { ExportType } from "@/constants/Order";

@Component({
    components: {
    }
})
export default class ExportOptions extends Vue {
    @Prop({default: 0}) totalRows!:number;
    @Prop({default: () => []}) selectedIds!: Array<string>;
    @Prop({default: () => 'đơn hàng'}) title: string;

    exportType: ExportType = ExportType.All;
    popupActive: boolean = false;
    
    constructor() {
        super();
    }

    open(){
        this.exportType = ExportType.All;
        this.popupActive = true;
    }

    exportFile(){
        let type = parseInt(this.exportType.toString());
        this.$emit("exportFile", type);
        this.popupActive = false;
    }

    get currentSearchText(){
        return `${this.totalRows} ${this.title} phù hợp với kết quả tìm kiếm hiện tại`;
    }
}

