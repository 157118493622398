






import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {TabState} from "@/store/order/order-state";
import { FormType } from "@/constants/Order";

@Component({
    components: {
    }
})
export default class SelectCellRenderer extends Vue {
    params: any = null;

    onSelect(){
        if (this.params.onClose) {
            this.params.onClose(this.params.data.id);
        }

        let orderId = this.params.data.id;

        if (this.$route && this.$route.name !== 'orderAdd') {
            this.$router.push({ name: 'orderAdd'});
        }

        const tab: TabState = {
            id: orderId,
            type: FormType.Return,
        };
        
        this.$store.dispatch('order/addTab', tab);
    }

    get orderId(){
        return this.params.data.id;
    }
}
