









































































































import {AgGridVue} from 'ag-grid-vue';
import Component from 'vue-class-component'
import {Ref, Vue, Watch} from 'vue-property-decorator';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import LinkCellRenderer from '@/components/ag-grid/cell-render/LinkCellRenderer.vue'
import CellRendererStatus from '@/views/order/order-return/cell-renderer/CellRendererStatus.vue'
import CellRendererActions from '@/views/order/order-list/cell-renderer/CellRendererActions.vue'
import CustomPinnedRowRenderer from "@/views/order/order-list/CustomPinnedRowRenderer.vue";
import {OrderService} from '@/views/order/OrderService';
import {ReturnService} from '@/views/order/ReturnService';
import {SettingColumnService} from '@/services/SettingColumnService';

import _filter from "lodash/filter";
import _map from "lodash/map";

import {ActionResult} from '@/models/ActionResult';
import {OrderGetByIdResult} from "@/models/order/OrderGetByIdResult";
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";

import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import FilterList from "@/components/filter/FilterList.vue";
import SelectPageSize from "@/components/select-page-size/select-page-size.vue";
import {QueryLoadOption, SortingInfo} from "@/models/QueryLoadOptions";
import {LoadResult} from "@/models/LoadResult";
import {formatNumber} from "@/common";
import {FilterStateResult} from "@/models/filter/filter-state-result";
import OrderReturnList from '@/views/order/order-return/OrderReturnList.vue';
import moment from 'moment';
import ReturnPrint from "@/views/order/return-print/ReturnPrint.vue";
import {TabState} from "@/store/order/order-state";
import {ExportType, FormType} from '@/constants/Order';
import ExportOptions from "@/views/order/order-list/ExportOptions.vue";
import _cloneDeep from "lodash/cloneDeep";

@Component({
    name: 'OrderList',
    components: {
        FilterList,
        SelectPageSize,
        AgGridVue,
        LinkCellRenderer,
        CellRendererStatus,
        CellRendererActions,
        SettingColumns: () => import(/* webpackChunkName: "SettingColumns" */ '@/components/SettingColumns.vue'),
        OrderReturnList,
        ReturnPrint,
        ExportOptions
    }
})

export default class OrderList extends Vue {
    @Ref('settingColumnsRef') settingColumnsRef!: any;
    @Ref('orderReturnList') orderReturnList !: any;
    @Ref('returnPrint') returnPrint!: any;
    @Ref('exportOptions') exportOptions!: any;

    private returnService: ReturnService;
    private orderService: OrderService;
    private settingColumnService: SettingColumnService;

    active: Boolean = false;
    searchQuery = '';
    gridOptions: any = null;
    gridApi: any = null;
    columnApi: any = null;
    columnDefs: any = null;
    gridColumnApi: any = null;
    maxPageNumbers = 7;
    totalRows = 0;
    totalPages: number = 0;
    currentPage = 1;
    order: OrderGetByIdResult = new OrderGetByIdResult();

    settingColumnConfig: SettingColumnResult = new SettingColumnResult();
    defaultSettingColumns: any = [];
    wasSidebarOpen: any = null;

    defaultColDef = {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        filter: true,
    };

    // search
    attributeOptions: FilterAttributeResult[] = [];
    loadOptions: QueryLoadOption = new QueryLoadOption();
    attributeSearchByKeywords = ['normalizedName'];
    selectFieldRequired: string[] = ['id', 'code', 'fromOrderId', 'fromOrderCode'];

    orders: any = [];
    pinnedTopRowData: any = null;

    // Cell Renderer Components
    celRenderComponents: any = {
        LinkCellRenderer,
        CellRendererStatus,
        CellRendererActions
    };

    frameworkComponents: any = {
        CustomPinnedRowRenderer
    };

    // Export.
    onRowSelected: any = null;
    selectedIds: Array<string> = [];

    constructor() {
        super();
        this.returnService = new ReturnService();
        this.orderService = new OrderService();
        this.settingColumnService = new SettingColumnService();
        this.loadOptions.sort = [new SortingInfo('createTime')];
    }

    beforeMount() {
        this.gridOptions = {
            rowSelection: 'multiple',
            suppressCellSelection: false,
            localeText: {
                noRowsToShow: 'Không có dữ liệu',
            },
            suppressRowClickSelection: true
        };

        this.getDefaultColumnSetting();
        this.getColumnDefaultApi();

        this.settingColumnService.getByModuleName('Return').then((result: ActionResult<SettingColumnResult>) => {
            this.settingColumnConfig = result.data as SettingColumnResult;
            if (!this.settingColumnConfig) {
                this.settingColumnConfig = new SettingColumnResult('Return');
                this.settingColumnConfig.columnConfigData = this.defaultSettingColumns;
            }

            this.getFieldSelectFromResult();

            // const filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
            // if (!filterState || filterState.searchValue !== null) {
            //     this.search();
            // }
            this.search();

            if (this.settingColumnsRef)
                this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);
        });

        this.onRowSelected = (params: any) => {
            let data = params.data;

            if (params.node.selected) {
                this.selectedIds.push(data.id);
            } else {
                this.selectedIds = _filter(this.selectedIds, (item: any) => {
                    return item !== data.id;
                });
            }
        };
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;

        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
    }

    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    }

    @Watch('currentPage')
    onCurrentPageChange(value: number, oldValue: number) {
        this.search();
    }

    exportPdf() {
        // this.orderService.exportPdf('d856e5c3-651c-4c82-8c89-eb99446991a9')
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    searchByFilter(filterQuery: string) {
        this.loadOptions.filterQuery = filterQuery;
        this.search();
    }

    beginSearch(page: number) {
        if (this.searchQuery == undefined || this.searchQuery.length < 2) {
            return;
        }

        this.search();
    }

    showModalFilter(value: FilterAttributeResult[]) {
        if (!value || value.length === 0) {
            this.renderAttributeOptions();
        }
    }

    createData(contacts: any) {
        let result: any = [];
        let sumTotalPriceReturn = contacts.reduce((acc: any, curr: any) => acc + parseInt(curr.payAmount), 0);
        let sumPaidPrice = contacts.reduce((acc: any, curr: any) => acc + parseInt(curr.paidPrice), 0);

        result.push({
            payAmount: sumTotalPriceReturn,
            paidPrice: sumPaidPrice,
        });

        return result;
    }

    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.getFieldSelectFromResult();
        this.search();
    }

    changePageSize(pageSize: number) {
        this.gridApi.paginationSetPageSize(pageSize);
        this.currentPage = 1;
        this.setFilterState(this.currentPage, pageSize, this.loadOptions);

        this.search();
    }

    openOrderReturnList() {
        this.orderReturnList.openPopup();
    }

    private renderAttributeOptions() {
        this.attributeOptions = this.returnService.getAttributeOptions();
    }

    onSortChange(e: any) {
        const columnSort = e.api.getSortModel();
        if (columnSort) {
            this.loadOptions.sort = [new SortingInfo(columnSort[0].colId, columnSort[0].sort === 'desc')];
            this.search();
        }
    }

    onReturnFastSelected() {
        const tab: TabState = {
            id: '',
            type: FormType.Return,
        };

        this.$store.dispatch('order/addTab', tab);
        this.$router.push({name: 'orderAdd'});
    }

    print() {
        const orderIds = this.gridApi.getSelectedRows().map((order: any) => {
            return order.id;
        });
        this.returnPrint.print(orderIds);
    }

    private async search() {
        this.openLoading(true);

        const filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (filterState) {
            this.loadOptions.filterQuery = filterState.loadOption.filterQuery;
            this.gridApi.paginationSetPageSize(filterState.pageSize);
        }

        this.loadOptions.skip = (this.currentPage - 1) * this.paginationPageSize;
        this.loadOptions.take = this.paginationPageSize;
        this.loadOptions.remoteSelect = false;

        this.returnService.search(this.loadOptions).finally(() => {
            this.openLoading(false);
        }).then((result: LoadResult) => {
            this.orders = result.data;
            this.totalRows = result.totalCount;
            this.setTotalPage(result.totalCount);
            this.pinnedTopRowData = this.createData(this.orders);
        })
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }

    private setTotalPage(totalRows: number) {
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.paginationPageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.paginationPageSize));
        }
    }

    get paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 20;
    }

    openExportOptions() {
        this.exportOptions.open();
    }

    async onExportFile(exportType: ExportType) {
        let loadOptions = _cloneDeep(this.loadOptions) as any;
        loadOptions.exportType = exportType;

        if (exportType === ExportType.Selected) {
            loadOptions.selectedIds = this.selectedIds;
        }

        this.openLoading(true);
        await this.returnService.exportExcel(loadOptions);
        this.openLoading(false);
    }

    private getFieldSelectFromResult() {
        const columnSelect = _filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
            return item.isSelected;
        });

        const fieldSelect = _map(columnSelect,
            (item: SettingColumnSelect) => {
                return item.field
            });

        this.loadOptions.select = this.selectFieldRequired.concat(fieldSelect).filter((idx: string) => {
            return idx !== 'actions' && idx !== 'selection';
        });
    }

    private getDefaultColumnSetting() {
        this.defaultSettingColumns = [
            new SettingColumnSelect('ReceiptCode', 'code', true),
            new SettingColumnSelect('OrderCode', 'fromOrderCode', true),
            new SettingColumnSelect('CreatorFullName', 'creatorFullName', true),
            new SettingColumnSelect('OrderDate', 'orderDate', true),
            new SettingColumnSelect('CreateTime', 'createTime', true),
            new SettingColumnSelect('CustomerName', 'customerName', true),
            new SettingColumnSelect('StoreName', 'storeName', true),
            new SettingColumnSelect('TotalPriceOriginal', 'totalPrice', true),
            new SettingColumnSelect('TotalPriceReturn', 'payAmount', true),
            new SettingColumnSelect('PaidPriceReturn', 'paidPrice', true),
            new SettingColumnSelect('ReturnCharge', 'returnCharge', true),
            new SettingColumnSelect('Status', 'status', true),
        ]
    }

    private getColumnDefaultApi() {
        this.columnDefs = [
            {
                isDefault: true,
                headerName: '',
                field: 'selection',
                filter: false,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: true,
                width: 70,
            },
            {
                headerName: "Mã phiếu",
                field: 'code',
                width: 150,
                filter: true,
                cellRendererFramework: LinkCellRenderer,
                cellRendererParams: {
                    routeName: 'returnDetail'
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                pinnedRowCellRendererParams: {style: {color: "blue"}}
            },
            {
                headerName: 'Mã đơn hàng',
                field: 'fromOrderCode',
                width: 150,
                filter: true,
                cellRendererFramework: LinkCellRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        routeName: 'orderDetail',
                        id: params.data.fromOrderId
                    }
                },
                pinnedRowCellRendererParams: {style: {color: "blue"}}
            },
            {
                headerName: this.$t('CreatorFullName'),
                field: 'creatorFullName',
                filter: true,
                width: 150,
            },
            {
                headerName: this.$t('OrderDate'),
                field: 'orderDate',
                filter: true,
                width: 200,
                cellRenderer: (data: any) => {
                    if (data.value) {
                        return moment(data.value).format('DD-MM-YYYY HH:mm')
                    } else {
                        return '';
                    }
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer"
            },
            {
                headerName: this.$t('CreateTime'),
                field: 'createTime',
                filter: true,
                width: 200,
                cellRenderer: (data: any) => {
                    if (data.value) {
                        return `<i class="text-sm">` + moment(data.value).format('DD-MM-YYYY HH:mm') + `</i>`
                    } else {
                        return '';
                    }
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer"
            },
            {
                headerName: this.$t('CustomerName'),
                field: 'customerName',
                filter: true,
                width: 250,
            },
            {
                headerName: "Kho hàng",
                field: 'storeName',
                filter: true,
                width: 150,
            },
            {
                headerName: "Nguyên giá theo đơn",
                field: 'totalPrice',
                filter: true,
                width: 170,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                cellStyle: {textAlign: "right"},
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            },
            {
                headerName: "Tiền hàng trả",
                // field: 'totalPriceReturn',
                field: 'payAmount',
                filter: true,
                width: 125,
                cellStyle: {textAlign: "right"},
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                }
            },
            {
                headerName: "Tiền trả lại khách",
                field: 'paidPrice',
                filter: true,
                width: 125,
                cellStyle: {textAlign: "right"},
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                }
            },
            {
                headerName: "Phí trả hàng",
                field: 'returnCharge',
                filter: true,
                width: 125,
                cellStyle: {textAlign: "right"},
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            },
            {
                headerName: this.$t('Status'),
                field: 'status',
                filter: true,
                width: 125,
                cellRendererFramework: CellRendererStatus,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            },
            // {
            //     headerName: 'Actions',
            //     field: 'actions',
            //     width: 120,
            //     cellRendererFramework: CellRendererActions,
            //     pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            //     cellStyle: {textAlign: "center"},
            //     filter: false,
            // }
        ];
    }

    private setFilterState(currentPage: number, pageSize: number, loadOption: QueryLoadOption) {
        let filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (!filterState) {
            filterState = new FilterStateResult();
        }

        filterState.pageSize = pageSize;
        filterState.currentPage = currentPage;
        filterState.loadOption = loadOption;
        filterState.module = this.$route.fullPath;
        filterState.attributeOptions = this.attributeOptions;

        // Cập nhật lại filter state
        this.$store.dispatch('filter/setFilterState', filterState);
    }
}
